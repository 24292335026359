<template>
  <div class="content md flex-box vertical">
    <div class="area">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="vertical" label-align="left" class="flex-grow">
        <a-form-model-item label="堂口名称" prop="tangkou_id">
          <a-select
            placeholder="选择堂口"
            :options="tkOptions"
            v-model="form.tangkou_id"
            allowClear
            show-search
            option-filter-prop="children"
            @change="tangkouChange"
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="部门主管" prop="director">
          <a-select placeholder="选择部门主管" v-model="form.director" allowClear>
            <a-select-option :value="director" v-for="director in directorList" :key="director">
              <open-data type="userName" :openid="director" />
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="出库类别" prop="category">
          <a-select placeholder="选择出库类别" :options="outboundOptions" v-model="form.category"></a-select>
        </a-form-model-item>
        <a-form-model-item label="出库日期" prop="ex_warehouse_date" extra="仅允许选择当前及之前的日期">
          <a-date-picker
            v-model="form.ex_warehouse_date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="选择日期"
            style="width: 100%;"
            :disabled-date="disabledDate"
            :allow-clear="false"
          />
        </a-form-model-item>
        <template v-if="form.category == 4">
          <a-form-model-item label="借用日期" prop="borrow_date">
            <a-date-picker
              style="width: 100%"
              v-model="form.borrow_date"
              placeholder="请选择借用日期"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              allowClear
            />
          </a-form-model-item>
          <a-form-model-item label="归还日期" prop="repay_date">
            <a-date-picker
              style="width: 100%"
              v-model="form.repay_date"
              placeholder="请选择归还日期"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              allowClear
            />
          </a-form-model-item>
        </template>
        <a-form-model-item class="info-list" prop="add_goods">
          <a-space>
            <a-button type="primary" @click="addInfo">添加出库商品</a-button>
            <a-button type="primary" @click="searchPurchase" :disabled="orderDisabled">
              申购单号出库
            </a-button>
          </a-space>
          <a-table
            :columns="columns"
            :data-source="form.add_goods"
            class="info-table"
            :row-key="(record) => record.key"
          >
            <div class="row-btn" slot="action" slot-scope="text, record, index">
              <a class="txt-btn" @click.stop="editRow(record, index)">编辑</a>
              <a class="txt-btn danger" @click.stop="delRow(record, index)">删除</a>
            </div>
          </a-table>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-space class="footer">
      <a-button type="primary" @click="handleSave">保存</a-button>
    </a-space>
    <BasicModal
      :title="`${edit ? '编辑' : '添加'}出库商品`"
      :initial="single"
      v-model="visible"
      :rules="editRules"
      :footer="edit ? 1 : 2"
      :success="addSuccess"
    >
      <template v-slot:edit="{ form }">
        <a-form-model-item label="商品名称" prop="goods_id" :extra="getCommodityExtra(form)">
          <GoodsSelect
            v-model="form.goods_id"
            :form="form"
            :options="goodsOptions"
            @change="goodsChange($event, form)"
          />
        </a-form-model-item>
        <a-form-model-item label="出库数量" prop="ex_warehouse_num">
          <a-input-number
            v-model="form.ex_warehouse_num"
            placeholder="请输入数字"
            :min="1"
            v-bind="form.goods ? { max: form.fixed_assets_id ? 1 : form.goods.goods_num } : null"
            :step="1"
            allowClear
            style="width: 100%;"
          ></a-input-number>
        </a-form-model-item>
        <!-- <a-form-model-item label="固定资产" :class="form.is_fixed_assets ? 'mb-0' : ''">
          <a-switch v-model="form.is_fixed_assets"></a-switch>
        </a-form-model-item> -->
        <a-form-model-item label="固定资产">
          <a-select
            placeholder="输入固定资产编号或名称搜索"
            :options="assetsOptions"
            v-model="form.fixed_assets_id"
            allow-clear
            show-search
            :filter-option="false"
            :not-found-content="assetsFetching ? undefined : null"
            @change="assetsChange($event, form)"
            @search="assetsSearch"
          >
            <a-spin v-if="assetsFetching" slot="notFoundContent" size="small" />
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea
            v-model="form.notes"
            placeholder="填写出库备注"
            :autoSize="{ minRows: 2, maxRows: 6 }"
            allowClear
          ></a-textarea>
        </a-form-model-item>
      </template>
    </BasicModal>
    <BasicModal title="从申购单号导入" v-model="orderVisible" :rules="orderRules" :success="orderSuccess">
      <template v-slot:edit="{ form }">
        <a-form-model-item label="申购单号" prop="subscribe_num">
          <a-input v-model="form.subscribe_num" placeholder="请输入申购单号" allowClear></a-input>
        </a-form-model-item>
      </template>
    </BasicModal>
    <OrderModal v-model="infoVisible" :type="2" :form="form" :info="info" @success="resetFrom"></OrderModal>
  </div>
</template>
<script>
import moment from "moment";
import {
  clone,
  dataConvertOptions,
  isEmpty,
  randomString,
  stringConvertTimestamp,
  debounce,
} from "../../common/js/tool";
import { getTemple } from "../../common/js/storage";
import BasicModal from "./components/BasicModal.vue";
import OrderModal from "./components/OrderModal.vue";
import GoodsSelect from "./components/GoodsSelect.vue";
import { outboundOptions, fixedAssetsOptions } from "../../common/asset/type";

const dateKeys = ["ex_warehouse_date", "borrow_date", "repay_date"];

export default {
  name: "OutboundAdd",
  components: {
    BasicModal,
    OrderModal,
    GoodsSelect,
  },
  data() {
    this.assetsLastFetchId = 0;
    this.assetsSearch = debounce(this.assetsSearch, 300);
    return {
      form: {
        category: 1,
        ex_warehouse_date: new Date().pattern("yyyy-MM-dd"),
      },
      rules: {
        tangkou_id: [{ required: true, message: "请选择堂口", trigger: "change" }],
        director: [{ required: true, message: "请选择主管", trigger: "change" }],
        category: [{ required: true, message: "请选择入库类别", trigger: "change" }],
        ex_warehouse_date: [{ required: true, message: "请选择入库日期", trigger: "change" }],
        add_goods: [{ required: true, message: "请添加入库商品", trigger: "change" }],
        borrow_date: [{ required: true, message: "请选择借用日期", trigger: "change" }],
        repay_date: [{ required: true, message: "请选择归还日期", trigger: "change" }],
      },
      outboundOptions,
      fixedAssetsOptions,
      unitOptions: [],
      columns: [
        {
          title: "SKU",
          customRender: (text) => {
            return text?.goods?.sku || "-";
          },
        },
        {
          title: "商品名称",
          customRender: (text) => {
            return text?.goods?.name || "-";
          },
        },
        { title: "商品数量", dataIndex: "ex_warehouse_num" },
        { title: "备注", dataIndex: "notes" },
        { title: "操作", dataIndex: "action", fixed: "right", width: 100, scopedSlots: { customRender: "action" } },
      ],
      directorList: [],
      tkOptions: [],
      visible: false,
      single: null,
      editIndex: undefined,
      editRules: {
        goods_id: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        unit: [{ required: true, message: "请选择商品单位", trigger: "change" }],
        ex_warehouse_num: [{ required: true, message: "出库数量至少为1", trigger: "blur", min: 1, type: "number" }],
        // fixed_assets_id: [{ required: true, message: "请选择固定资产", trigger: "change" }],
      },
      /* 申购单号 */
      orderVisible: false,
      orderRules: {
        subscribe_num: [{ required: true, message: "请输入申购单号", trigger: "blur" }],
      },
      /* 申购单详情 */
      infoVisible: false,
      info: null,

      goodsOptions: [],

      assetsFetching: false,
      assetsOptions: [],
    };
  },
  computed: {
    orderDisabled() {
      let form = this.form;
      return !form.tangkou_id || !form.director || (form.category == 4 && (!form.borrow_date || !form.repay_date));
    },
    edit() {
      return this.single;
    },
  },
  created() {
    this.getTk();
  },
  methods: {
    // 获取堂口
    getTk() {
      this.$axios({
        url: "/admin/ketang",
        method: "GET",
        params: {
          sort: "order,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.tkOptions = dataConvertOptions(res.data);
      });
    },
    // 商品变更
    goodsChange(val, form) {
      if (form.ex_warehouse_num) {
        form.ex_warehouse_num = undefined;
      }
    },
    // 获取固定资产列表
    assetsSearch(keyword) {
      if (isEmpty(keyword)) {
        this.assetsOptions = [];
      } else {
        this.assetsLastFetchId += 1;
        const fetchId = this.assetsLastFetchId;
        this.assetsOptions = [];
        this.assetsFetching = true;
        this.$axios({
          url: "/admin/asset-fixed",
          method: "GET",
          noTempleFilter: true,
          params: {
            sort: "-id",
            pageSize: 100,
            expand: "goods",
            goods_name: keyword,
            temple_id: getTemple(),
          },
        })
          .then((res) => {
            if (fetchId !== this.assetsLastFetchId) {
              // for fetch callback order
              return;
            }
            this.assetsOptions = res.data.map((item) => {
              return {
                ...item,
                label: `${item.asset_code}-${item.goods?.name}`,
                value: item.id,
              };
            });
          })
          .finally(() => {
            this.assetsFetching = false;
          });
      }
    },
    // 堂口改变
    tangkouChange(val) {
      this.$set(this.form, "director", undefined);
      if (val) {
        let target = this.tkOptions.find((item) => item.value === val);
        this.directorList = target.admin_user_ids?.split(",") || [];
        this.$set(this.form, "director", this.directorList[0]);
      } else {
        this.directorList = [];
      }
    },
    assetsChange(val, form) {
      if (val && form.ex_warehouse_num > 1) {
        form.ex_warehouse_num = 1;
      }
    },
    getCommodityExtra(form) {
      let res = null;
      if (form.goods_id) {
        res = `当前库存：${form.goods?.goods_num || 0}${form.goods?.goodsUnit?.name || ""}`;
      }
      return res;
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    editRow(record, index) {
      this.single = record;
      this.editIndex = index;
      this.visible = true;
    },
    delRow(record, index) {
      this.$confirm({
        title: "提示",
        content: `确定删除此商品吗？`,
        onOk: () => {
          this.form.add_goods.splice(index, 1);
        },
      });
    },
    resetFrom() {
      this.form = {
        category: 1,
        ex_warehouse_date: new Date().pattern("yyyy-MM-dd"),
      };
    },
    // 保存
    handleSave() {
      let ruleForm = this.$refs.ruleForm;
      ruleForm.validate((flag) => {
        if (flag) {
          this.$confirm({
            title: "提示",
            content: `确定保存此出库单吗？`,
            onOk: () => {
              let form = clone(this.form);
              dateKeys.forEach((key) => {
                let value = form[key];
                if (!isEmpty(value)) {
                  form[key] = stringConvertTimestamp(value);
                }
              });
              this.$axios({
                url: "/admin/asset-ex-warehouse/create-goods",
                method: "POST",
                data: form,
              }).then((res) => {
                if (res.error == 0) {
                  this.$message.success("添加成功！");
                  this.resetFrom();
                } else {
                  this.$message.error(res.msg);
                }
              });
            },
          });
        }
      });
    },
    // 清除编辑参数
    clearEdit() {
      this.single = null;
      this.editIndex = undefined;
    },
    // 添加入库商品
    addInfo() {
      this.clearEdit();
      this.visible = true;
    },
    // 添加入库商品
    addSuccess(form, close) {
      if (form.goods.goods_num < form.ex_warehouse_num) {
        this.$message.error("商品库存不足！");
        return;
      }
      this.$set(this.form, "add_goods", this.form.add_goods || []);
      if (form.key) {
        // 编辑
        this.form.add_goods[this.editIndex] = form;
      } else {
        // 新增
        this.form.add_goods.push({ key: randomString(16), ...form });
      }
      close();
    },
    // 申购单号入库
    searchPurchase() {
      this.orderVisible = true;
    },
    // 查找申购单
    orderSuccess(form, close) {
      this.$axios({
        url: "/admin/asset-subscribe",
        method: "GET",
        params: {
          "filter[subscribe_num]": form.subscribe_num,
          expand: "fixed.goods",
          "filter[or][0][fid][neq]": 0, // 固定资产
          "filter[or][1][link_id][is]": "null", // 普通申购单
        },
      }).then((res) => {
        const data = res.data;
        if (data.length > 0) {
          this.info = {
            subscribe_num: form.subscribe_num,
            list: data,
          };
          this.infoVisible = true;
          close();
        } else {
          this.$message.error("不存在该申购单号!");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info-table {
  margin-top: 12px;
}
.mb-0 {
  margin-bottom: 0;
}
</style>
