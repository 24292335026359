<template>
  <a-modal :maskClosable="false" v-model="visible" :width="400" :title="title" @ok="confirm" @cancel="close">
    <template v-slot:footer v-if="!footer">
      <slot name="footer" />
    </template>
    <template v-slot:footer v-else-if="footer == 1">
      <a-button @click="close(true)">取消</a-button>
      <a-button type="primary" @click="confirm(true)">确定</a-button>
    </template>
    <template v-slot:footer v-else-if="footer == 2">
      <a-button @click="close(true)">取消</a-button>
      <a-button type="primary" @click="confirm(false)">添加</a-button>
      <a-button type="primary" @click="confirm(true)">添加并关闭</a-button>
    </template>
    <a-form-model class="item-form" ref="editForm" :model="single" :rules="rules" layout="vertical" label-align="left">
      <slot name="edit" v-bind:form="single" />
    </a-form-model>
  </a-modal>
</template>

<script>
import { clone } from "../../../common/js/tool";
export default {
  name: "BasicModal",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    value: {
      type: Boolean,
      default: false,
    },
    rules: Object,
    width: {
      type: Number,
      default: 400,
    },
    success: Function,
    footer: {
      type: Number,
      default: 1,
    },
    initial: Object,
  },
  data() {
    return {
      visible: false,
      single: {},
    };
  },
  watch: {
    value: {
      handler(val) {
        if (!val) {
          this.$refs.editForm?.resetFields();
        }
        this.setVisible();
      },
      immediate: true,
    },
  },
  created() {
    this.setVisible();
  },
  methods: {
    setVisible() {
      this.visible = this.value;
      this.setForm();
    },
    setForm() {
      if (!this.visible) {
        return;
      }
      if (this.initial) {
        this.single = clone(this.initial);
      } else {
        this.single = {};
      }
    },
    close(hide) {
      this.$refs.editForm.resetFields();
      this.single = {};
      if (hide) {
        this.visible = false;
        this.$emit("input", this.visible);
      }
    },
    confirm(hide) {
      this.$refs.editForm.validate((flag) => {
        if (flag) {
          let form = this.single;
          if (typeof this.success === "function") {
            this.success(clone(form), () => this.close(hide));
          } else {
            this.close(hide);
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
